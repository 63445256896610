import Nitrous from '../lib/nitrous'

/* eslint-disable object-shorthand */

function genItemId() {
  return `item-${crypto.randomUUID()}`
}

const DEFAULT_INPUT_TYPE = ['input', 'select', 'textarea'].join(',')

export default (klass) => class WizardMixin extends klass {

  addNestedForm({
    list,
    inputType = DEFAULT_INPUT_TYPE,
    template,
    wrapper
  }) {
    let clone = null
    if (template) {
      clone = this.#renderNitrousFields({ list, template })
    } else {
      clone = this.#renderNestedForm({ list, inputType })
    }
    wrapper.lastElementChild.before(clone)
  }

  hideRadioButtonMoreDetails({ target }) {
    this.hideTarget(target)
  }

  hideTarget(target) {
    this.toggleTarget(target, false)
  }

  removeErrors(element) {
    element.children.forEach((child) => {
      child.classList.remove(this.errorClass)

      const errorMessage = child.querySelector("[class$='__error']")
      if (errorMessage) {
        errorMessage.remove()
      }
    })
  }

  removeNestedForm({
    button,
    wrapper,
    list,
    inputType = DEFAULT_INPUT_TYPE
  }) {
    const itemId = button.dataset.itemId
    const item   = wrapper.querySelector(`#${itemId}`)

    if (list.length === 1) return

    if (item) {
      item.remove()

      // Remove removed item from list
      const filteredList = list.filter((value) => value.id !== itemId)

      // Handle inputs
      filteredList.forEach((target, x) => {
        const inputs = target.querySelectorAll(inputType)

        inputs.forEach((input) => {
          this.updateNestedInputAttributes({ wrapper: target, input: input, count: x })
        })
      })
    }
  }

  showTarget(target) {
    this.toggleTarget(target, true)
  }

  showRadioButtonMoreDetails({ target }) {
    this.showTarget(target)
  }

  toggleCheckboxMoreDetails({ input, target }) {
    this.toggleTarget(target, input.checked)
  }

  toggleCheckboxNoneOfTheAbove({
    input,
    targets,
    noneValue = 'none_of_the_above',
    dispatchInput = false,
    noneTarget = false
  }) {
    if (!input.checked) return

    if (input.value === noneValue || input === noneTarget) {
      const notNoneTargets = targets.filter((target) => target.value !== noneValue)
      notNoneTargets.forEach((target) => {
        const wasChecked = target.checked
        target.checked = false

        if (dispatchInput && wasChecked) {
          target.dispatchEvent(new InputEvent('input'))
        }
      })
    } else {
      const noneInput   = noneTarget || targets.find((target) => target.value === noneValue)
      const wasChecked  = noneInput.checked
      noneInput.checked = false

      if (dispatchInput && wasChecked) {
        noneInput.dispatchEvent(new InputEvent('input'))
      }
    }
  }

  toggleNoneInverseSelection({ input, target }) {
    this.toggleTarget(target, !input.checked)
  }

  toggleNoneInverseSelections({ input, targets }) {
    this.toggleTargets(targets, !input.checked)
  }

  toggleTarget(target, value) {
    target.classList.toggle(this.hideClass, !value)
  }

  toggleTargets(targets, value) {
    targets.forEach((target) => {
      this.toggleTarget(target, value)
    })
  }

  updateNestedInputAttributes({
    wrapper,
    input,
    count,
    clear = false
  }) {
    const isRadio = input.type === 'radio'
    const ogName  = input.name

    let labelEl
    let inputId

    if (isRadio) {
      const ogId = input.id
      labelEl    = input.parentElement

      inputId       = ogId.replace(/_\d+_/, `_${count}_`)
      input.name    = ogName.replace(/\[\d+\]/, `[${count}]`)

      if (clear) input.checked = false
    } else {
      const ogLabel = ogName.replace(/\[\]$/, '').replace(/\[/g, '_').replace(/\]/g, '')
      labelEl       = wrapper.querySelector(`label[for='${ogLabel}']`)

      inputId    = input.name.replace(/\[/g, '_').replace(/\]/g, '')
      input.name = ogName.replace(/\[\d+\]/, `[${count}]`)

      if (clear) input.value = ''
    }

    input.id = inputId
    if (labelEl) labelEl.setAttribute('for', inputId)
  }

  // ==== Private

  #renderNestedForm({ list, inputType }) {
    const template = list[0]
    const count    = list.length
    const clone    = template.cloneNode(true)
    const inputs   = clone.querySelectorAll(inputType)
    const button   = clone.querySelector('button[data-item-id]')

    // Add new form ID
    clone.id = genItemId()

    this.removeErrors(clone)

    // Handle button states
    button.dataset.itemId = clone.id
    button.classList.remove(this.hideClass)

    // Handle inputs
    inputs.forEach((input) => {
      this.updateNestedInputAttributes({
        input:   input,
        count:   count,
        wrapper: clone,
        clear:   true
      })
    })

    return clone
  }

  #renderNitrousFields({ template, list }) {
    return Nitrous.render({
      template,
      values: { id: genItemId(), index: list.length }
    })
  }

}
