// matches {{key}} or {{ key }} (including url-encoded)
const HANDLEBARS_RGX = /(?:\{\{|%7B%7B)\s*([a-zA-Z0-9_]+)\s*(?:\}\}|%7D%7D)/g
// matches __key__ (surrounded by underscores, or at start/end of string)
// this is because Rails FormBuilder mangles parentheses in "id" and "for" attributes
// e.g. some_form[some_relation][{{index}}][some_attribute] becomes some_form_some_relation___index___some_attribute
const UNDERSCORES_RGX = /(?<=_|^)__([a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*)__(?=_|$)/g

function replaceTextInTextNode(element, pattern, replacement) {
  const newText = element.wholeText
    .replace(pattern, replacement)
    .replace(/\n\n+/g, '\n\n') // no more than 2 newlines in a row
    .trim()
  const lines = newText.split('\n')
  const newNodes = lines.flatMap((value, index) => {
    const textNode = document.createTextNode(value)
    if (index === 0) return textNode
    return [document.createElement('br'), textNode]
  })
  element.replaceWith(...newNodes)
}

function replaceTextInAttribute(attribute, pattern, replacement) {
  let newValue = attribute.value.replace(pattern, replacement)

  if (['id', 'for'].includes(attribute.name)) {
    newValue = newValue.replace(UNDERSCORES_RGX, replacement)
  }

  attribute.value = newValue
}

function replaceTextInAttributes(element, pattern, replacement) {
  if (!element.attributes) return

  for (const attribute of element.attributes) {
    replaceTextInAttribute(attribute, pattern, replacement)
  }
}

function replaceTextInChildNodes(element, pattern, replacement) {
  for (const node of element.childNodes) {
    // eslint-disable-next-line no-use-before-define
    replaceInDom(node, pattern, replacement)
  }
}

/**
 * Find and replace a pattern with a string within a Dom element.
 * See `String.prototype.replace` for details on pattern and replacement arguments.
 *
 * @param {Node} element The element in which to perform the replacement
 * @param {RegExp|string} pattern The pattern to search for
 * @param {string|function} replacement A replacement string or replacer function
 */
function replaceInDom(element, pattern, replacement) {
  if (element instanceof Text) {
    replaceTextInTextNode(element, pattern, replacement)
    return
  }

  if (element instanceof Element) {
    replaceTextInAttributes(element, pattern, replacement)
  }

  replaceTextInChildNodes(element, pattern, replacement)
}

/**
 * Find and replace all {{key}} patterns found in a Dom element, using key:value pairs from object.
 *
 * @param {Node} element The element in which to perform the replacement
 * @param {object} object The variables to replace
 */
export default function interpolate(element, object) {
  replaceInDom(element, HANDLEBARS_RGX, (_, prop) => object[prop]?.toString() || '')
}
